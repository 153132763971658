import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/Seo"

import { SecondaryHero } from "../../components/Heros"

const PrivacyPolicy = () => (
  <Layout>
    <Seo title="Sorted | Privacy policy" keywords={[`Sorted`, `Products`, `Hero`, `React`, `Pro`]} />
    <SecondaryHero theme="pink">
      <h1 class="text-center text-white underline display"><span>Website Privacy Notice</span></h1>
    </SecondaryHero>
    <div class="container">
      <p>Thank you for visiting sorted.com. Here at Sorted, we are committed to respecting your privacy. This notice is to explain how we may use personal information we collect when you:</p>
      <ul className="styled">
        <li>browse our website</li>
        <li>submit your details via our website, for example to make an enquiry or sign up for our email newsletter</li>
        <li>contact us using the contact details contained on our website.</li>
      </ul>
      <p>References to <strong>we</strong>, <strong>our </strong>or <strong>us</strong> in this privacy notice are to Sorted Holdings Limited (company number 8609014) and each of its direct and indirect subsidiaries, including Sorted Group Limited (company number 9060564). The registered office of both Sorted Holdings Limited and Sorted Group Limited is at 3rd Floor, The Union, Albert Square, Manchester, M2 6LW.</p>
      <p>The use of our products will also involve the storage and use of personal data, for example personal data relating to users of those products and to our clients’ customers:</p>
      <ul className="styled">
        <li>in most cases we hold and use this personal data on our client’s behalf, and you should contact the relevant client directly if you require information about how your personal data is handled or have any requests to make in respect of it</li>
        <li>where we have responsibility for any personal data related activities which are connected to the use of one of our products, we will publish a separate privacy notice for that product – please see the relevant product page on our website or contact us for more information</li>
      </ul>
      <h3>1. PERSONAL INFORMATION</h3>
      <ul>
        <ul className="styled">
          <li>In common with most websites, when you browse our website we will collect:
            <ul className="styled">
              <li>your IP address</li>
              <li>information about the device that you are using to access our website, such as device type, browser and operating system</li>
              <li>usage data about your visit, such as the website you arrived from, the date and time of your visit and the path you take through our website (which pages you visit and in what order).</li>
            </ul>
          </li>
          <li>Information about your usage of our website may be collected through cookies and/or log files. For more information about the cookies we use, see paragraph 6.</li>
          <li>If you choose to interact with our website, or contact us using the contact details contained on it, you may provide us with additional information about yourself, for example your name, email address, phone number, job title and the company you work for.</li>
          <li>Due to the nature of our products, most of the information that you provide us with is likely to relate to your business, rather than be personal information about you, but if you do choose to provide us with any additional personal information then this will also be handled in accordance with this privacy notice.</li>
        </ul>
      </ul>
      <h3>2. USES MADE OF THE INFORMATION</h3>
      <ul className="styled">
        <li>We are committed to protecting your privacy, and will only use your personal information in accordance with applicable data protection legislation, including the General Data Protection Regulation and the Data Protection Act 2018.</li>
        <li>Specifically, we may use your personal information as follows:
        <ul className="styled">
          <li>where you have expressly consented to us doing so, including:
          <ul className="styled">
            <li>where you have signed up for our emails we will send you our latest content, newsletters and details about upcoming events and products</li>
            <li>where you have submitted an enquiry via our website or otherwise contacted us and requested a response we will contact you about your enquiry, including to (as applicable):
              <ul className="styled">
                <li>provide you with more information about our products and services</li>
                <li>book a demo</li>
                <li>provide you with a commercial proposal regarding your business’ use of our products and services</li>
                <li>otherwise respond to any queries that you have raised</li>
              </ul>
            </li>
            <li>where it is necessary for compliance with legal obligations, including for record keeping and regulatory compliance purposes</li>
            <li>where we otherwise have a legitimate interest in doing so, including:
                <ul className="styled">
                    <li>ensuring that our website is presented in the most effective manner for you and your computer, so that we can provide the most positive user experience</li>
                    <li>analysing usage data to learn more about how our website is used, to help us to provide a positive user experience and improve and increase usage of our website</li>
                    <li>detecting and investigating any potential misuse of our website, to protect ourselves and other users</li>
                  </ul>
                </li>
                <li>Where you have given us your consent to use your personal information in a particular manner, you have the right to withdraw this consent at any time, which you may do by contacting us as described in paragraph 10. Please note however that the withdrawal of your consent will not affect any use of your personal information made before you withdrew your consent.</li>
              </ul>
            </li>
          </ul>
        </li>
      </ul>
      <h3>3. SHARING OF YOUR PERSONAL INFORMATION WITHIN OUR GROUP</h3>
      <ul className="styled">
        <li>Our group consists of various companies. Where you provide personal information to one company within our group it may be shared with other companies within our group. This is in our legitimate interests to enable us to operate our group effectively and to enable us to provide you with an efficient service.</li>
        <li>All members of our group are bound by this privacy notice.</li>
      </ul>
      <h3>4. CIRCUMSTANCES IN WHICH WE MAY SHARE YOUR PERSONAL INFORMATION</h3>
      <ul className="styled">
        <li>We may share your personal information with:
          <ul className="styled">
            <li>where you make an enquiry about our products or services, any partners through which we offer those products or services</li>
            <li>providers of outsourced services, such as hosting and IT support or maintenance services (to the extent necessary for the provider to provide those outsourced services to us)</li>
            <li>professional advisors, such as solicitors, auditors and insurers</li>
            <li>the prospective seller or buyer of any business or assets in the event that we sell or buy any business or assets</li>
            <li>law enforcement authorities in connection with any suspected misuse of the website or other suspected illegal activity</li>
          </ul>
        </li>
        <li>In certain cases the sharing of your personal information to a third party as described above may involve your personal information being transferred outside of the United Kingdom. This may be to:
          <ul className="styled">
            <li>a country in the European Economic Area or that is otherwise considered to have data protection rules that are equivalent to those in the United Kingdom</li>
            <li>a country which is not considered to have the same standards of protection for personal data as those in the United Kingdom, in which case we will take all steps required by law to ensure sufficient protections are in place to safeguard your personal information, including where appropriate putting in place contractual terms approved by the relevant regulatory authorities.</li>
          </ul>
        </li>
      </ul>
      <h3>5. RETENTION, UPDATING AND REMOVAL OF YOUR PERSONAL INFORMATION</h3>
      <ul className="styled">
        <li>Where you provide us with personal information we may retain this information for future use, including:
          <ul className="styled">
            <li>providing you with news and updates of a type you have asked to receive</li>
            <li>corresponding with you regarding any enquiries that you have made and, where relevant, providing you with information about our products</li>
            <li>monitoring fraud and other illegal or unlawful activity</li>
            <li>record keeping purposes</li>
          </ul>
        </li>
        <li>The duration for which we retain your personal information will differ depending on the type of information and the reason why we collected it from you. However, in some cases personal information may be retained on a long term basis: for example, personal information that we need to retain for legal purposes will normally be retained for at least six years in accordance with usual commercial practice and regulatory requirements.</li>
        <li>It is important to ensure that the personal information we hold about you is accurate and up-to-date, and you should let us know if anything changes, for example if you change your phone number or email address. You can contact us using the details in paragraph 10.</li>
        <li>If you want to stop receiving any information from us, such as news and updates, please let us know by contacting us using the details in paragraph 10. You can also click the unsubscribe button on any email newsletter. It may take up to one month to unsubscribe you from marketing.</li>
        <li>Your decision to stop receiving information from us may result in the deletion of some of the personal information that we hold about you, but we may still retain personal information we require for other purposes, including where it is required for record keeping purposes.</li>
        <li>In certain circumstances you may be entitled to request the erasure of personal information that we hold about you. To make a request of this nature, please contact us, providing full details of the personal information you want to be erased and the reason(s) for your request. We will consider all requests upon receipt and confirm to you whether we are able to agree to your request.</li>
      </ul>
      <h3>6. COOKIES</h3>
      <ul className="styled">
        <li>When you browse our website, we use “cookies” to keep track of your visit and give you a better browsing experience. This includes the following cookies:
          <ul>
            <li><strong>functional cookies</strong>, to ensure that our website functions correctly and that it can be presented to you in the most effective manner</li>
            <li><strong>Google Analytics </strong>and <strong>HubSpot</strong>, to track how you make use of our site (to opt out of being tracked by Google Analytics across all websites visit http://tools.google.com/dlpage/gaoptout)</li>
          </ul>
        </li>
      </ul>
      <h3>7. SECURITY</h3>
      <ul className="styled">
        <li>The protection of your personal information is very important to us and we have in place various security measures to ensure that your personal information is kept secure.</li>
        <li>However, you should be aware that the method you use to get in contact with us, such as email or phone, may not be entirely secure and your personal information may be susceptible to being intercepted in transit. Equally, your personal information is at risk if a malicious third party gains access to your systems or to any other place where your personal information is stored, such as your email account.</li>
        <li>You should therefore take all reasonable steps to keep your personal information secure, including taking care about what personal information you send us, choosing a secure password for your email account and not disclosing your passwords to anybody else. You should use a unique password for every account.</li>
      </ul>
      <h3>8. ENQUIRIES, ISSUES AND COMPLAINTS</h3>
      <ul className="styled">
        <li>You are entitled by law to ask for a copy of your personal information at any time. Please contact us using the details in paragraph 10.</li>
        <li>In the unlikely event that you have any concerns about how we use your personal information, please contact us as described in paragraph 10. This includes situations where you want to request the rectification or erasure of your personal information, restrictions to be placed around how we use your personal information, or to object to a particular use.</li>
        <li>If you make a complaint about our handling of your personal information, it will be dealt with in accordance with our complaints handling procedure. In the first instance it will be reviewed by an appropriate member of our team who will respond to you within 28 days. If you are dissatisfied with this response you may request that your complaint be escalated, in which case it will be passed to a senior person in our business who will review your complaint and the initial response and provide a further response within 28 days of your request to escalate the matter.</li>
        <li>If we are unable to resolve your complaint, you may make a complaint to the Information Commissioner’s Office. Please see <a rel="noopener noreferrer" href="https://ico.org.uk/make-a-complaint/" target="_blank">https://ico.org.uk/make-a-complaint/</a> for more information.</li>
      </ul>
      <h3>9. CHANGES TO THIS PRIVACY NOTICE</h3>
      <ul className="styled">
        <li>We reserve the right to alter this privacy notice at any time. Such alterations will be posted on our website. You can also obtain an up-to-date copy of our privacy notice by contacting us as described in paragraph 10. Should you object to any alteration, please contact us.</li>
      </ul>
      <h3>10. CONTACTING US</h3>
      <ul className="styled">
        <li>If you need to contact us about this notice or any matters relating to the personal information we hold on you, you can contact us using the details below:</li>
        <li><strong>By post:</strong> Sorted Group Limited, 3rd Floor, The Union, Albert Square, Manchester, M2 6LW</li>
        <li><strong>By email: </strong><span><a href="mailto:info@sorted.com">info@sorted.com</a></span></li>
      </ul>
      <h3>11. FURTHER INFORMATION</h3>
      <ul className="styled">
        <li>We hope that the contents of this privacy notice address any queries that you may have about the personal information we may hold about you and what we may do with it. However, if you do have any further queries, comments or requests, please contact us as described in paragraph 10 above.</li>
        <li>Whilst this privacy notice sets out a general summary of your legal rights in respect of personal information, this is a very complex area of law. More information about your legal rights can be found on the Information Commissioner’s website at <a rel="noopener noreferrer" href="https://ico.org.uk/your-data-matters/" target="_blank">https://ico.org.uk/your-data-matters/</a>.</li>
      </ul>
    </div>
  </Layout>
)

export default PrivacyPolicy
